/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #0040FF #FFFFFF;
  }
  
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #FFFFFF;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #0040FF;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #4775FF;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #4775FF;
  }
  