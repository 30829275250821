.mkt
{
    padding: 2% 4%;
    .LogoSubTitle
    {
        color: #fff;
        margin: 4px;
        font-weight: 300;
        font-style: normal;
        font-size: 18px;
    }
}

.mkt-feeds
{
    padding: 0 14%;
    .Divader
    {
        display: none;
    }
    .AvatarMKT
    {
        width: 80px;
        height: 80px;
    }
    .TitleName
    {
        margin-left: 24px;
        display: inline-flex;
        a
        {
            flex-wrap: nowrap;
            display: flex;
            text-decoration: none;
        }
    }
    .DescriptionMKT
    {
        margin-left: 22px;
    }
    h3
    {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        font-style: bold;
        margin-top: -10px;
        margin-left: 10px;
    }
    p
    {
        color: #fff;
        margin: 4px;
        font-weight: 300;
        font-style: normal;
        font-size: 18px;
    }
    .WhatsAppLogo
    {
        height: 34px;
        width: 34px;
    }
}


@media screen and (max-width: 1025px) {
    .mkt
    {
        padding: 2% 4%;
        .LogoSubTitle
        {
            color: #fff;
            margin: 4px;
            font-weight: 300;
            font-style: normal;
            font-size: 16px;
        }
    }
    .mkt-feeds
    {
        padding: 0 8%;
        .Divader
        {
            display: block;
        }
        .AvatarMKT
        {
            width: 74px;
            height: 74px;
            margin-left: -5px;
        }
        .TitleName
        {
            margin-left: 24px;
            display: inline-flex;
            a
            {
                flex-wrap: nowrap;
                display: flex;
                text-decoration: none;
            }
        }
        .DescriptionMKT
        {
            margin-left: 22px;
        }
        h3
        {
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            font-style: bold;
            margin-top: -5px;
            margin-left: 10px;
        }
        p
        {
            color: #fff;
            margin: 4px;
            font-weight: 300;
            font-style: normal;
            font-size: 16px;
        }
        .WhatsAppLogo
        {
            height: 34px;
            width: 34px;
        }
    }
}

@media screen and (max-width: 769px) {
    .mkt
    {
        padding: 2% 4%;
        .LogoSubTitle
        {
            color: #fff;
            margin: 4px;
            font-weight: 300;
            font-style: normal;
            font-size: 14px;
        }
    }
    .mkt-feeds
    {
        padding: 0 2%;
        .Divader
        {
            display: block;
        }
        .AvatarMKT
        {
            width: 44px;
            height: 44px;
            margin-left: -5px;
        }
        .TitleName
        {
            margin-left: 24px;
            display: inline-flex;
            a
            {
                flex-wrap: nowrap;
                display: flex;
                text-decoration: none;
            }
        }
        .DescriptionMKT
        {
            margin-left: 22px;
        }
        h3
        {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            font-style: bold;
            margin-top: -1px;
            margin-left: 10px;
        }
        p
        {
            color: #fff;
            margin: 4px;
            font-weight: 300;
            font-style: normal;
            font-size: 14px;
        }
        .WhatsAppLogo
        {
            height: 24px;
            width: 24px;
        }
    }
}

@media screen and (max-width: 480px) {
    .mkt
    {
        padding: 2% 4%;
        .LogoSubTitle
        {
            color: #fff;
            margin: 4px;
            font-weight: 300;
            font-style: normal;
            font-size: 14px;
        }
    }
    .mkt-feeds
    {
        padding: 0 2%;
        .Divader
        {
            display: block;
        }
        .AvatarMKT
        {
            width: 44px;
            height: 44px;
            margin-left: -5px;
        }
        .TitleName
        {
            margin-left: 28px;
            display: inline-flex;
            a
            {
                flex-wrap: nowrap;
                display: flex;
                text-decoration: none;
            }
        }
        .DescriptionMKT
        {
            margin-left: 24px;
        }
        h3
        {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            font-style: bold;
            margin-top: -1px;
            margin-left: 10px;
        }
        p
        {
            color: #fff;
            margin: 4px;
            font-weight: 300;
            font-style: normal;
            font-size: 14px;
        }
        .WhatsAppLogo
        {
            height: 24px;
            width: 24px;
        }
    }
}