$bg-primary: #0D5ED8;
$bg-primary-disable: #C5DCFF;
$bg-secundary: #fff;
$bg-regular: #fff;
$bg-danger: #DC3545;

$color-primary: #fff;
$color-primary-disable:#fff;
$color-secundary: #1B4D9D;
$color-regular: #6B7786;
$color-danger: #fff;

.btn-primary
{
    background-color: $bg-primary !important;
    color: $color-primary !important;
    border-color: $color-primary !important;
}

.btn-primary-disable
{
    background-color: $bg-primary-disable !important;
    color: $color-primary-disable !important;
    border-color: $color-primary-disable !important;
}

.btn-secondary
{
    background-color: $bg-secundary !important;
    color: $color-secundary !important;
    border-color: $color-secundary !important;
}

.btn-regular
{
    background-color: $bg-regular !important;
    color: $color-regular !important;
    border-color: $color-regular !important;
}

.btn-danger
{
    background-color: $bg-danger !important;
    color: $color-danger !important;
    border-color: $color-danger !important;
}

.action-button
{
    padding: 11px 165px !important;
}