$FontSize: 24px; 
$FontSize3: 32px;
$FontSize2: 40px;
$FontSize1: 54px;

$Blue: #0040FF;
$Yellow: #F5F884;
$AcesseAquiBackGround: #F0F0F0;

h1
{
    font-size: $FontSize1;
    font-style: bold;
    font-weight: 700;
}
h2
{
    font-size: $FontSize2;
    font-style: medium;
    font-weight: 500;
}
h3
{
    font-size: $FontSize3;
    font-style: medium;
    font-weight: 500;
}
p
{
    font-size: $FontSize;
    font-style: normal;
    font-weight: 300;
}

.Center
{
    text-align: center;
}