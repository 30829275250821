.removeFile{
    color: rgb(255, 0, 4);    
}
.linkFile{
    color: rgb(20, 117, 235);
}
.downloadFile{
    color: rgb(122,122,122);
}
.removeFile:hover{
    color: #ff0004; 
    filter: brightness(85%);
}
.linkFile:hover{
    color: #1475eb;
    filter: brightness(85%);
}
.downloadFile:hover{
    color: rgb(122,122,122);
    filter: brightness(85%);
}
.UploadSucess
{
    background: rgb(207,207,207);
background: linear-gradient(270deg, rgba(207,207,207,0.03) 10%, rgba(168,247,153,0.71) 95%);
}
.UploadErro
{
    background: rgb(207,207,207);
background: linear-gradient(270deg, rgba(207,207,207,0.03) 10%, rgba(252,54,54,0.711922268907563) 95%);
}
