.CenterText
{
  text-align: center;
}
.LeftText
{
  text-align: left;
}
.form-filter
{
    #form-title
    {
        font-family: 'Poppins';
        font-weight: 500;
        font-style: normal;
        font-size: 32px;
    }
}

.table-filtro
{
    #table-title
    {
        font-family: 'Poppins';
        font-weight: 500;
        font-style: normal;
        font-size: 24px;
    }
}

.notFound
{
  h2
  {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #6B7786;
  }
}